.navbar {
  position: sticky;
  top: 0;
  height: 80px;
  background-color: var(--white);
  box-shadow: 0 4px 4px hsla(0, 0%, 0%, 0.195);
  z-index: 5;
}

.navbar .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo-section {
  width: auto;
  height: auto;
  object-fit: cover;
}

.logo-section > a > img {
  width: 240px;
  height: 55px;
  cursor: pointer;
}

.navbar-links {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 60px;
  padding-top: 20px;
}

.navbar-links > li {
  list-style: none;
}
.navbar-links > li > a {
  text-decoration: none;
  color: var(--black);
  cursor: pointer;
}

.navbar-links > li > a:hover {
  color: var(--primary-green);
  font-weight: 700;
}
.navbar-links > li > a .active {
  color: var(--primary-green);
  cursor: pointer;
  font-weight: 700;
}

.navbar-mob {
  display: none;
}

/* Responsive at 1024 */
@media screen and (max-width: 1024px) {
  .navbar-links {
    column-gap: 40px;
  }
}
/* Responsive at 768px */
@media screen and (max-width: 768px) {
  .navbar-items {
    right: 20px;
  }

  .navbar-links {
    display: none;
  }

  .navbar-mob {
    display: flex;
  }

  .navbar-items {
    position: absolute;
    text-align: left;
    margin-top: 20px;
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    right: 0px;
    width: 300px;
    height: 100vh;
    background-color: var(--primary-blue);
  }

  .navbar-items > li {
    list-style: none;
  }
  .navbar-items > li > a {
    text-decoration: none;
    color: var(--white);
    font-size: 20px;
  }
  .social-links {
    display: flex;
    justify-content: flex-start;
    column-gap: 30px;
    margin-top: 40px;
  }
  .social-links a {
    text-decoration: none;
    color: var(--white);
  }
}

/* Responsive at 768px */
@media screen and (max-width: 768px) {
}

/* Responsive at 425px */
@media screen and (max-width: 426px) {
  .logo-section > a > img {
    width: 185px;
    height: 50px;
  }
}
